import configJson from "./auth_config.json";

/*
import axios from 'axios';
// import { AuthConfigAPI } from "./services/api.js"

const get_auth_configs = async () => {

  const apiHost = `${process.env.REACT_APP_API_HOST}`;
  console.log('apiHost', apiHost)

  const instance = axios.create({
    baseURL: apiHost,
    timeout: 5000
  });

  const response = await instance.get(`/api/v1/oidc_configs/`);

  return response.data.results
};


const query = new URLSearchParams(window.location.search)
*/

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  /*
  let auth_configs_list = await get_auth_configs();
  console.log('auth_configs_list', auth_configs_list);
  let auth_config = {};
  if(query.get('config')){
      auth_config = auth_configs_list.filter((c)=>{
          return c.name==query.get('config')
      })[0];
  }else{
      //  FIXME Temporarily just fetch the second (auth0)
      auth_config = auth_configs_list[1];
  }
  console.log('auth_config', auth_config);
  */

  // const audience = configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
  //     ? configJson.audience
  //     : null;

  // const audience = auth_config.audience && auth_config.audience !== "YOUR_API_IDENTIFIER"
  //     ? auth_config.audience
  //     : null;

  return {
    // domain: auth_config.domain,
    // apiPort: process.env.REACT_APP_FRONT_API_PORT,
    apiPort: configJson.apiPort,
    apiHost: process.env.REACT_APP_API_HOST,
    apiOrigin: process.env.REACT_APP_API_HOST,
    //clientId: auth_config.clientId,
    ////...(audience ? { audience } : null),
  };
}
