import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import styles from './Hero.module.css';
import { useAuth } from "react-oidc-context";

//import logo from "../assets/logo.svg";

const Hero = ({title, lead_small, lead, pre_action, action=''}) => {
    const auth = useAuth();
    const doLogin = () => {
        void auth.signinRedirect()
    }
    if(action!==''){
        action = <a onClick={doLogin}
                    //tag={RouterNavLink}
                    className="btn btn-lg btn-primary">Login</a>
    }
    return (
      <div className="text-center hero my-5">
        <img className="mb-3 app-logo" src='https://webobjects2.cdw.com/is/image/CDW/cdw-logo-220x130-2023-v2' alt="React logo" width="120" />
        <h1 className='mb-4'>{title}</h1>
        <div className={styles.joke + ' lead_small'}>{lead_small}</div>
        <p className='lead'>{lead}</p>
        <p className='lead_action'>{action}</p>
        <div className='pre_action'>{pre_action}</div>
      </div>
    )
};

export default Hero;
