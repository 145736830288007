import React, { useEffect, useState, Fragment } from "react";
import Hero from "../components/Hero";
import LoginConfigList from '../components/LoginConfigList'

const Cover = () => {

    const [ quote, setQuote ] = useState('');

    const quotes = [
        'I wondered why the frisbee kept getting bigger and bigger. Then it hit me.',
        "Dogs can't operate MRI machines.  But catscan.",
        'RIP boiling water.... you will be mist.',
        'Someone stole my mood ring. I don’t know how I feel about that.',
        'I got carded at a liquor store, and my Blockbuster card accidentally fell out. The cashier said never mind.'
    ];

    const rand_quote = () => {
        setQuote(quotes[Math.floor(Math.random() * quotes.length)])
    };

    useEffect(() => {
    	const loadJokes = () => {

            rand_quote();
            setInterval(rand_quote, 5000)
    	};
    	loadJokes();
    }, []);


    let lead1 = (<Fragment>
        <blockquote><em>{quote}</em></blockquote>
    </Fragment>);

    const pre_action = (
        <Fragment>
            <p>You can click on one of the login options below to switch to that Identity Provider (IdP)</p>
            <LoginConfigList/>
        </Fragment>
    );

    return (
      <Fragment>
        <Hero title='Dad Jokes Fail'
              lead_small={lead1}
              lead='Login to access all of the interesting resources here'
              pre_action={pre_action}
              action='/login'/>

        <hr />
      </Fragment>
    )
};

export default Cover;
