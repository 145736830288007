import React from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
// import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth } from "react-oidc-context";
import { withAuthenticationRequired } from "react-oidc-context";
import { useState } from 'react'

// Consider using
// https://www.npmjs.com/package/react-gravatar


export const ProfileComponent = () => {
  // const { user } = useAuth0();
  const auth = useAuth();
  // const user = auth.user;
  const [gravatarUrl, setGravatarUrl] = useState(0);

  let image_url = `https://robohash.org/${auth.user.profile.email}`;
  if(auth.user.profile.picture){
  	image_url = auth.user.profile.picture;
  }
  /*
  */

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}><img
				src={image_url}
				alt="Profile"
				className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"/>
		</Col>
        <Col md>
          <h2>{auth.user.name}</h2>
          <p className="lead text-muted">{auth.user.profile.email}</p>
        </Col>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(auth.user, null, 2)}</Highlight>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
