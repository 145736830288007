import React from "react";
import { useState } from 'react';
import { getConfig } from "../config";
import { getAuthConfig } from "../get_auth_config"
// import { useEffectAsync } from "../utils/useEffectAsync"
import { useEffect } from "react"
import styles from './Footer.module.css';

// https://www.linkedin.com/pulse/how-use-asyncawait-react-useeffect-hook-hasibul-islam/
/*
function useEffectAsync(effect, inputs) {
  useEffect(() => {
    return effect();
  }, inputs);
}
*/

const Footer = () => {

    const [authConfig, setAuthConfig] = useState(0);

    useEffect(() => {
		const loadConfig = async () => {
			let configs = await getAuthConfig();
			setAuthConfig(configs);
		}
		loadConfig()
    }, []);
    /* */


    return (
  <footer className="bg-light p-3 text-center">
    <div  className={styles.logo} />
    <p>
      Sample project built by the Cyber Defense Engineering team at <a href="https://cdw.com"><span>CDW</span></a>
    </p>
  <div className={'offset-md-3 w-50 ' + styles.midfoot}>
	  <code><pre>config:{JSON.stringify(getConfig(), null, 2)}</pre></code>
	  <code><pre>authConfig:{JSON.stringify(authConfig, null, 2)}</pre></code>
  </div>
  </footer>
)

};

export default Footer;
