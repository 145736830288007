import React, { useState } from "react";
// import {  Alert } from "reactstrap";
// import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth } from "@auth0/auth0-react";
import { useAuth } from "react-oidc-context";
import { withAuthenticationRequired } from "react-oidc-context";
import { Input, InputGroup, InputGroupText, Button} from "reactstrap";
import Highlight from "../components/Highlight";
// import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { useRocksAPI } from '../services/api';
import { getAuthConfig } from "../get_auth_config"
import { useEffect } from "react"
import Loading from "../components/Loading";

export const ExternalApiComponent = () => {
  // const { apiPort, apiOrigin = `http://localhost:${apiPort}`, audience } = getConfig();
  const { apiOrigin = `https://auth.sso.rocks/api` } = getConfig();

  // const [authConfig, setAuthConfig] = useState(0);
  const [audience, setAudience] = useState(0);

  useEffect(() => {
  	const loadConfig = async () => {
		let config = await getAuthConfig();
		// setAuthConfig(config);
		console.log('co: ', config.audience);
		console.log('co2: ', config);
		setAudience(config.audience);
  	};
  	loadConfig();
  }, []);

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    api_path: "external",
    error: null,
  });


  const onFormInputChange = ({ target }) => {

    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const [rocksapi] = useRocksAPI();

  // const { user } = useAuth0();
  const auth = useAuth();
  // const user = auth.user

  const callApi = async () => {

    setState({
      ...state,
      showResult: false,
      apiMessage: '',
    });
    const response = await rocksapi.get(state.api_path);

    setState({
      ...state,
      showResult: true,
      apiMessage: response.data,
    });

  };

  return (
    <>
      <div className="mb-5">
        <h1>External API</h1>
        <p className="lead">
          Hello, {auth.user?.profile?.name}! <br/>Ping the external API by clicking the button below. There are several paths you can try.
        </p>
        <ul>
          <li><code>external</code> is a simple test of the api authentication</li>
          <li><code>home/content</code> is the unauthenticated source for the homepage of this application</li>
          <li><code>rocks/</code> is the authenticated list of rocks available.</li>
          <li><code>jokes/</code> is the authenticated list of jokes available.</li>
          <li><code>puppies/</code> is the authenticated list of puppies.</li>

        </ul>

        <p>
          This will call a local API at <code>{apiOrigin}</code>. An access
    token is sent as part of the request's <code>Authorization</code> header. The API
    will validate it using the API's audience value.
        </p>

        <InputGroup>
          <InputGroupText>
           /api/v1/
          </InputGroupText>
          <Input placeholder="external" name='api_path' onChange={onFormInputChange}/>
        </InputGroup>

        <Button
          color="primary"
          className="mt-5"
          onClick={callApi}
          disabled={!audience}
        >
          Ping the API {audience}
        </Button>
      </div>

      <div className="result-block-container">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Highlight>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </Highlight>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
