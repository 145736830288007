import {get_auth_configs} from "../get_auth_config"
import { useState } from 'react';
import { useEffect } from "react"
import LoginConfigRow from "./LoginConfigRow"

const LoginConfigList = () => {

    const [authConfigs, setAuthConfigs] = useState([]);

	useEffect(() => {
		const loadOrders = async () => {
			let configs = await get_auth_configs();
			console.log('got configs', configs);
			setAuthConfigs(configs);

		};
		loadOrders();
	}, []);

    // console.log('config_list', config_list);
    return (
        <table className='table'>
        <thead>
            <tr>
                <th>name</th><th>issuer</th>
            </tr>
		</thead>
		<tbody>{authConfigs.map((config, idx)=>{
			return (<LoginConfigRow config={config} key={idx}/>)
		})}</tbody>
        </table>

    )
};

export default LoginConfigList;
