import React, { Fragment } from "react";

import LoginConfigList from "../components/LoginConfigList";

const LoginConfig = () => (
  <Fragment>
    <LoginConfigList />
  </Fragment>
);

export default LoginConfig;
