import React, { Fragment } from "react";

import { Row,
		 Container } from "reactstrap";
import { Calendar } from "react-feather";
import Content from "../components/Content";
import DataTable from "../components/DataTable";
import HomeNav from "../components/HomeNav";
import DataChart from "../components/DataChart";
import styles from './Home.module.css';

// import './Home.module.css'

// Make this a page that looks more like something you would
// see after looking in. Maybe look at the dashboard sample. See
// https://getbootstrap.com/docs/4.0/examples/dashboard/


const Home = () => {


    return (
    <Fragment>
    <Container id='this-id' className={styles.font}>
      <Row>
        <HomeNav/>

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">Dashboard</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2">
                <button className="btn btn-sm btn-outline-secondary">Share</button>
                <button className="btn btn-sm btn-outline-secondary">Export</button>
              </div>
              <button className="btn btn-sm btn-outline-secondary dropdown-toggle">
                <Calendar className={styles.feather}/> This week
              </button>
            </div>
          </div>

          <DataChart/>

          <h2>Section title</h2>
          <DataTable/>
        </main>
      </Row>
    </Container>

    <hr />
    <Content />
  </Fragment>
)
};

export default Home;
