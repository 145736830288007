import { Card, CardBody, CardText, CardTitle } from "reactstrap"

const JokeItem = ({joke}) => {

    // console.log('config_list', config_list);
    return (<Card style={{ width: '18rem', margin: '2px' }}>
		<CardBody>
			<CardTitle tag='h5'>A Joke</CardTitle>
			<CardText>{joke.joke.replace('\n', '<br/>')}</CardText>
		</CardBody>
	</Card>)
};

export default JokeItem;
