
const LoginConfigRow = ({config}) => {

    // console.log('config_list', config_list);
	const link_url = `/?config=${config.name}`;
    return (<tr>
			<td><a href={link_url}>{config.name}</a></td>
			<td>{config.issuer}</td>
		</tr>)
};

export default LoginConfigRow;
