import styles from '../views/Home.module.css';
import { NavLink as RouterNavLink } from "react-router-dom";
import { Nav,
         NavLink,
         NavItem} from "reactstrap"
import {
		Home as HomeIcon,
		File, PlusCircle, Calendar,
		ShoppingCart, Users, BarChart2, Layers } from "react-feather";

const HomeNav = () => {

    // console.log('config_list', config_list);
    return (
        <Nav className={"bg-light sidebar " + styles.sidebar}>
          <div className={styles.sidebar_sticky}>
            <Nav className="flex-column">
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                  <HomeIcon className={styles.feather}/> Dashboard <span className="sr-only">(current)</span>
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/dadjokes" exact>
                  <File className={styles.feather}/> Orders
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/sudoku" exact>
                  <ShoppingCart className={styles.feather}/> Products
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
				  <Users className={styles.feather}/> Customers
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                  <BarChart2 className={styles.feather}/> Reports
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                  <Layers className={styles.feather}/> Integrations
                </NavLink>
              </NavItem>
            </Nav>

            <h6 className={styles.sidebar_heading + " d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"}>
              <span>Saved reports</span>&nbsp;
              <a className={styles.feather + " d-flex align-items-center text-muted"} href="#"><PlusCircle /></a>
            </h6>
            <Nav className="flex-column mb-2">
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                  <File className={styles.feather}/> Current month
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                  <File className={styles.feather}/> Last quarter
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                  <File className={styles.feather}/> Social engagement
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink tag={RouterNavLink}
                        className={styles['nav-link']}
                        activeClassName=" active"
                        to="/" exact>
                <File className={styles.feather}/> Year-end sale
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Nav>
)};

export default HomeNav;
