import React, { useState } from "react";
// import {  Alert } from "reactstrap";
// import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth } from "@auth0/auth0-react";
import { useAuth } from "react-oidc-context";
import { withAuthenticationRequired } from "react-oidc-context";
import JokeItem from "../components/JokeItem";
import { useRocksAPI } from '../services/api';
import { useEffect } from "react"
import { Fragment } from 'react'
import { Container, Row } from 'reactstrap'
import HomeNav from "../components/HomeNav";
import Loading from "../components/Loading";
import styles from './Home.module.css';

export const ExternalApiComponent = () => {
  // const { apiPort, apiOrigin = `http://localhost:${apiPort}`, audience } = getConfig();
  // const { apiOrigin = `https://auth.sso.rocks/api` } = getConfig();
  const auth = useAuth();
  const [rocksapi] = useRocksAPI();

  // const [authConfig, setAuthConfig] = useState(0);
  const [jokes, setJokes] = useState([]);

  useEffect(() => {
  	const loadJokes = async () => {
		// setAuthConfig(config);
		if(rocksapi.get){
			console.log('rocksapi', rocksapi);
			console.log('rocksapi.get', rocksapi.get);
			const response = await rocksapi.get('/jokes/');
			console.log('jokes', response.data.results);
			setJokes(response.data.results);
		}
  	};
  	loadJokes();
  }, [rocksapi]);

  // const { user } = useAuth0();
  // const user = auth.user

  let jokes_list = [];

  if(jokes){
	  jokes_list = jokes.map((joke, idx)=>{
		console.log('joke', joke, idx)
		return <JokeItem joke={joke} key={idx}/>
	  });
  }
  /* {jokes?.jokes.map((joke, idx)=>{
	return <JokeItem joke={joke}/>
  })}
  */

  return (
	<Fragment>
		<Container className={styles.font}>
		  <Row>
			<HomeNav/>
				<main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
				 <h1>Dad Jokes</h1>

				  <h2>Section title</h2>
				  {jokes_list}
				</main>
		  </Row>
		</Container>
	  </Fragment>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
