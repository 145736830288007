import axios from 'axios';
import Cookies from 'js-cookie'
// import { AuthConfigAPI } from "./services/api.js"

const get_auth_configs = async () => {

  const apiHost = `${process.env.REACT_APP_API_HOST}`;
  console.log('apiHost', apiHost)

  const instance = axios.create({
    baseURL: apiHost,
    timeout: 5000
  });

  const response = await instance.get(`/api/v1/oidc_configs/`);

  return response.data.results
};


const query = new URLSearchParams(window.location.search)

async function getAuthConfig(config_name) {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.

  let auth_configs_list = await get_auth_configs();
  // console.log('auth_configs_list', auth_configs_list);
  let auth_config = {};
  let config_name_to_get;
  console.log('config_cookie', Cookies.get('config'));
  console.log('config_name', config_name);
  console.log('query config_name', query.get('config'));
  if(config_name !== undefined){
	  config_name_to_get = config_name
  }else if(query.get('config') !== null){
	  config_name_to_get = query.get('config')
  }else{

	  let config_cookie = Cookies.get('config')
	  config_name_to_get = config_cookie ? config_cookie : 'auth0';

      //  FIXME Temporarily just fetch the second (auth0)
      // auth_config = auth_configs_list[1];
  }

  Cookies.set('config', config_name_to_get);
  auth_config = auth_configs_list.find((c)=>c.name===config_name_to_get);
  console.log('auth_config', auth_config);

  return auth_config
}

export { get_auth_configs, getAuthConfig };
