import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
// import { Auth0Provider } from "@auth0/auth0-react"
import { AuthProvider } from "react-oidc-context";

// import history from "./utils/history"

import { getAuthConfig } from "./get_auth_config"

/*
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
*/

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
let	root = createRoot(document.getElementById('root'));

getAuthConfig().then((auth_config)=>{
    // const config = await getConfig();


	// Use https://github.com/authts/react-oidc-context instead
	// which is a generic react OIDC library
	/*
	get the authority part maybe the issuer?
	ExternalID: https://cdwciamtest.ciamlogin.com/<tenant-id>/oauth2/v2.0/
	Auth0: https://dev-3ybu17u2q8qlb8ep.us.auth0.com/
	*/

	const queryParams = new URLSearchParams(window.location.search)
	const invitation = queryParams.get("invitation")
	const organization = queryParams.get("organization")


	let extraQueryParams = {
		audience: auth_config.audience
	}
	if(invitation && organization){
		// if both of these exist, then this is an Auth0 membership invitation
		// starting point. We need to complete later authentication with these
		// attributes addded to the queryparams
		extraQueryParams['invitation'] = invitation;
		extraQueryParams['organization'] = organization;
        console.table('extraQueryParams', extraQueryParams);
	}

	const oidcConfig = {
	  authority: auth_config.issuer,

	  client_id: auth_config.client_id,
	  redirect_uri: `https://${window.location.hostname}/`,
      post_logout_redirect_uri: `https://${window.location.hostname}/`,
      popup_post_logout_redirect_uri: `https://${window.location.hostname}/`,
      extraQueryParams: extraQueryParams,
      scope: `${auth_config.scope}`,

      // silent_redirect_uri: url + "/sample-silent.html",
      // automaticSilentRenew: true,
	  onSigninCallback: (_user) => {
	    window.history.replaceState({}, document.title, window.location.pathname)
		/*
		// This is the auth0 version. I'm not sre which is better.
		history.push(
		  appState && appState.returnTo ? appState.returnTo : window.location.pathname
		);
		*/

	  }
	};

    root.render(
	  <AuthProvider {...oidcConfig} >
		<App />
	  </AuthProvider>,
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
});
serviceWorker.unregister();
