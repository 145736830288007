import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
import { Component } from "react"
import { useState, useEffect } from 'react';
import { Fragment } from "react";

const real_min = 0;
const real_max = 25000;
function randomNumberInRange(min, max) {
    let num = Math.floor(Math.random() * (max - min) + min);
    num = Math.max(num, real_min);
    num = Math.min(num, real_max);

    return num
}

const empty_data_array = (fill, length) => {
    fill = fill?fill:10000;
    length = length?length:7;

    return Array.from(Array(length).keys()).fill(fill)
};

/*
const colors = [
    '#007bff',
    '#d02c81',
];
*/
// Good for sequential data
// const colors = ["#e27c7c", "#a86464", "#6d4b4b", "#503f3f", "#333333", "#3c4e4b", "#466964", "#599e94", "#6cd4c5"];
const colors = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];
const data_labels = ["Dogs", "Cats", "Llamas", "Snakes", "Bears", "Reindeer", "Dolphins", "Gerbils", "Foxes"];

const random_initial_data = colors.map((c)=>{
    return empty_data_array(randomNumberInRange(10000, 15000))
})

const DATAPOINTS_TO_KEEP = 25;

class DataChart extends Component {
    // const [day_number, setDayNumber ] = useState(0);
    constructor(props) {
        super(props);
        this.state = {chart_data: false};
    }
    raw_numbers = random_initial_data;
    // chart_data = false;
    labels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    day_number = 0;
    timeoutId = null;

    // console.log('random_initial_data', random_initial_data);

    gen_data = () => {

        // setChartData(this_chart_data);
        // console.log('this.raw_numbers', this.raw_numbers);
        this.raw_numbers = this.raw_numbers.map((numbers, idx)=>{
            if(numbers.length > DATAPOINTS_TO_KEEP){
                numbers.shift(); /// pull first one off
            }
            numbers.push(randomNumberInRange(numbers[0]-5000, numbers[0]+5000));
            return numbers
        });

        // console.log('gen_data raw_numbers', this.raw_numbers);
        // raw_numbers.shift(); /// pull first one off
        // raw_numbers.push(randomNumberInRange(raw_numbers[0]-5000, raw_numbers[0]+5000));
        // return this_chart_data;

        // console.log('useEffect chart_data', chart_data);
        // console.log('gen_data raw_numbers', this.raw_numbers);

        // setDayNumber(day_number + 1);

        let datasets = this.raw_numbers.map((numbers, idx)=>{
            // console.log('numbers', numbers);
            // console.log('idx', idx);
            return {
              label: data_labels[idx],  // `Set ${idx+1}`,
              data: numbers,
              lineTension: 0.4,
              // lineTension: 1,
              backgroundColor: 'transparent',
              borderColor: colors[idx],
              borderWidth: 2,
              pointBackgroundColor: colors[idx]
            }

        });

        // this.labels.push(this.labels.shift());
        this.day_number++;
        if(this.labels.length > DATAPOINTS_TO_KEEP){
            this.labels.shift();
        }
        // this.labels.push(this.labels.shift());
        this.labels.push(`D${this.day_number}`);

        let this_chart_data = {
            // day_number: day_number,
            labels: this.labels,
            datasets: datasets
        };

        // console.log('this_chart_data', this_chart_data);
        // setLabels(labels);
        // setRawNumbers(new_raw_numbers);
        this.setState({chart_data: this_chart_data});
    };

    // useEffect(() =>{
    //     console.log('labels changed', labels);

    // }, [chart_data]);

    componentDidMount() {
        this.timeoutId = setInterval(() => {
            this.gen_data();
        }, 5000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    render(){

        let theChart = <Fragment/>
        if(this.state.chart_data !== false){
            theChart = (<Fragment><Line width='900' height='380' data={this.state.chart_data}/></Fragment>);
        }else{
            theChart = <p>Data Loading...</p>
        }

        return theChart
    }

}

const DataChartFunc = () => {
    // const [day_number, setDayNumber ] = useState(0);
    const [raw_numbers, setRawNumbers ] = useState(random_initial_data);
    const [chart_data, setChartData ] = useState(false);
    const [labels, setLabels ] = useState(["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]);
    const [day_number, setDayNumber] = useState(0);

    // console.log('random_initial_data', random_initial_data);

    const gen_data = () => {

        // setChartData(this_chart_data);
        raw_numbers.map((numbers, idx)=>{
            numbers.shift(); /// pull first one off
            numbers.push(randomNumberInRange(numbers[0]-5000, numbers[0]+5000));
        });

        console.log('gen_data raw_numbers', raw_numbers);
        // raw_numbers.shift(); /// pull first one off
        // raw_numbers.push(randomNumberInRange(raw_numbers[0]-5000, raw_numbers[0]+5000));
        // return this_chart_data;

        // console.log('useEffect chart_data', chart_data);
        console.log('useEffect raw_numbers', raw_numbers);

        // setDayNumber(day_number + 1);

        let datasets = raw_numbers.map((numbers, idx)=>{
            // console.log('numbers', numbers);
            // console.log('idx', idx);
            return {
              label: data_labels[idx],  // `Set ${idx+1}`,
              data: numbers,
              lineTension: 0.4,
              // lineTension: 1,
              backgroundColor: 'transparent',
              borderColor: colors[idx],
              borderWidth: 2,
              pointBackgroundColor: colors[idx]
            }

        });

        labels.push(labels.shift());
        // let new_day_number = day_number + 1;
        // setDayNumber(new_day_number);
        // labels.push(`Day ${day_number}`);
        // console.log('labels', labels);
        // console.log('day_number', day_number);

        let this_chart_data = {
            // day_number: day_number,
            labels: labels,
            datasets: datasets
        };

        console.log('this_chart_data', this_chart_data);
        // setLabels(labels);
        // setRawNumbers(new_raw_numbers);
        setChartData(this_chart_data);
    };

    // useEffect(() =>{
    //     console.log('labels changed', labels);

    // }, [chart_data]);

    useEffect(() => {
        // Use setTimeout to update the data every 2 seconds
        const timeoutId = setInterval(() => {
            gen_data();
        }, 2000);

        // setInterval(gen_data, 5000)
        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);  // leave an empty list so that this only fires on the first render


    let theChart = <Fragment/>
    if(chart_data !== false){
        theChart = (<Fragment><Line width='900' height='380' data={chart_data}/></Fragment>);
    }else{
        theChart = <p>Data Loading...</p>
    }

    return theChart

}

export default DataChart;
