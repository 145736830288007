import { useState, useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "react-oidc-context";
import { getConfig } from "../config";
import axios from 'axios';

const HomeContentAPI = async () => {

  const { apiPort, apiHost = `http://localhost:${apiPort}` } = getConfig();
  // console.log('apiHost', apiHost)
  // console.log('apiPort', apiPort)

  const instance = axios.create({
    baseURL: apiHost,
    timeout: 5000
  });

  const response = await instance.get(`/api/v1/home/content`);

  return response
};

const RocksAPI = class {
    constructor(token, apiOrigin) {
        console.log('in constructor');
        console.log('apiOrigin', apiOrigin);

        var axios_config = {
            baseURL: `${apiOrigin}/api/v1`,
            timeout: 5000
         }
        if(token){
            axios_config.headers = {
                'Authorization': `Bearer ${token}`
            }
        }
        this.instance = axios.create(axios_config);

    }
    get(path, params){
        params = params ? params : {};
        console.log(`in get ${path}`)
        return this.instance.get(path, params);
    }
}

const useRocksAPI = () => {
    const [rocksapi, setAPI] = useState([]);

	/*
    const {
      getAccessTokenSilently,
    } = useAuth0();
	*/
	const auth = useAuth();

    const { apiPort, apiHost = `http://localhost:${apiPort}` } = getConfig();

    useEffect( () => {

      async function fetchData(){
        var token;
		token = auth.user?.access_token;
		/*
        try {
            token = await getAccessTokenSilently();
        }catch{
            token = null;
        }
		*/
        if(token){
            console.log(`token: ${token.substring(0, 20)} ...`);
        }
        const api = new RocksAPI(token, apiHost);
        setAPI(api);
      }
      fetchData();
    // }, [apiOrigin, getAccessTokenSilently]);
    }, [apiHost, auth]);

    return [ rocksapi ]
}

export { HomeContentAPI, useRocksAPI, RocksAPI};
