import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import DadJokes from "./views/DadJokes";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import LoginConfig from "./views/LoginConfig";
import Cover from "./views/Cover";
import SudokuGame from "./views/SudokuGame";
// import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "react-oidc-context";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
    // const { isLoading, error } = useAuth0();
    const auth = useAuth();

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    // if (isLoading) {
    if (auth.isLoading) {
        return <Loading />;
    }
    if (!auth.isAuthenticated){
        return <Cover />
    }


    return (
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/external-api" component={ExternalApi} />
              <Route path="/login-config" component={LoginConfig} />
              <Route path="/dadjokes" component={DadJokes} />
              <Route path="/sudoku" component={SudokuGame} />
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
    );
};

export default App;
