import { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import Sudoku from "@francomay3/sudoku";
import HomeNav from "../components/HomeNav";
import styles from './Home.module.css';

const SudokuGame = () => {

    return (
        <Fragment>
        <Container id='this-id' className={styles.font}>
          <Row>
            <HomeNav/>

            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
              <h2>Play A Game</h2>
              <Sudoku/>
            </main>
          </Row>
        </Container>

      </Fragment>
    )
}

export default SudokuGame;
